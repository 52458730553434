import VoronoiTest from "./components/VoronoiTest";

function App() {
  return (
    <div>
      <VoronoiTest></VoronoiTest>
    </div>
  );
}

export default App;
