import { Delaunay } from "d3-delaunay";
import { useEffect, useRef, useState } from "react";
import range from "lodash/range";
import random from "lodash/random";
import groupBy from "lodash/groupBy";
import mean from "lodash/mean";
import { RandomPicture } from "random-picture";
import smooth from "smooth-polyline";
import {
  line,
  curveBasis,
  curveBumpY,
  curveBumpX,
  curveBundle,
  curveCardinal,
  curveLinear,
  curveMonotoneX,
  curveMonotoneY,
  curveNatural,
  curveStep,
} from "d3-shape";
import { schemeSet2 } from "d3-scale-chromatic";
import sample from "lodash/sample";

function randomPoint(width, height) {
  return [random(0, width), random(0, height)];
}

function randomPoints(num, width, height) {
  return range(num).map(() => randomPoint(width, height));
}

function getCenter(points) {
  const x = mean(points.map((p) => p[0]));
  const y = mean(points.map((p) => p[1]));

  return [x, y];
}

function generateGrid(width, height) {
  const gridFactor = 80;
  const deltaX = width / gridFactor;
  const deltaY = height / gridFactor;
  let out = [];
  for (let i = 0; i < gridFactor; i++) {
    const x = i * deltaX;
    for (let j = 0; j < gridFactor; j++) {
      const y = j * deltaY;
      const point = [x, y];
      out.push(point);
    }
  }
  return out;
}

function distortGrid(grid) {}

// https://math.stackexchange.com/questions/366474/find-coordinates-of-n-points-uniformly-distributed-in-a-rectangle
function distributeEven(numPoints, width, height) {
  //   const testPoints = randomPoints(numPoints * 100, width, height);
  const testPoints = generateGrid(width, height);
  let points = randomPoints(numPoints, width, height);
  const delaunay = Delaunay.from(points);
  //const voronoi = delaunay.voronoi([0, 0, width, height])

  for (let i = 0; i < 50; i++) {
    const closestPoints = testPoints.map((p, i) => ({
      site: delaunay.find(...p),
      density: i,
    }));
    const byIndex = groupBy(closestPoints, "site");
    //move points to baricenters
    points = points.map((p, i) => {
      const candidates = byIndex[i].map((idx) => testPoints[idx.density]);
      return getCenter(candidates);
    });
  }
  return points;
}

const movePoint = function (points, index, deltaX, deltaY) {
  let newPoints = [...points];
  newPoints[index] = [
    newPoints[index][0] + deltaX,
    newPoints[index][1] + deltaY,
  ];
  return newPoints;
};

const availableCurves = {
  curveBasis,
  curveBumpY,
  curveBumpX,
  curveBundle,
  curveCardinal,
  curveLinear,
  curveMonotoneX,
  curveMonotoneY,
  curveNatural,
  curveStep,
};

export default function VoronoiTest({
  width = 1200,
  height = 800,
  intialNumPoints = 20,
}) {
  const [cells, setCells] = useState([]);
  const [colors, setColors] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [curve, setCurves] = useState("curveBasis");

  const [ctx, setCtx] = useState();
  const ref = useRef();

  const [points, setPoints] = useState([]);
  const [controlPoints, setControlPoints] = useState([]);

  const [numPoints, setNumPoints] = useState(intialNumPoints);

  useEffect(() => {
    const promises = range(numPoints).map((p) => RandomPicture());
    Promise.all(promises).then((results) => {
      console.log("r", results);
      setPictures(results);
    });
  }, [numPoints]);

  useEffect(() => {
    // const points = range(numPoints).map((i) => {
    //   return [random(0, width), random(0, height)];
    // });
    // const delaunay = Delaunay.from(points);
    // const voronoi = delaunay.voronoi([0, 0, width, height]);

    // const polyCells = Array.from(voronoi.cellPolygons());
    // const polys = polyCells.map((cell, i) => voronoi.renderCell(i));
    // setCells(polys);
    // const colors = polyCells.map((cell) =>
    //   Math.floor(Math.random() * 16777215).toString(16)
    // );
    // setColors(colors);

    // const ctx = ref.current.getContext("2d");

    // setCtx(ctx);
    // ctx.clearRect(0, 0, width, height);

    // ctx.beginPath();
    // voronoi.renderBounds(ctx);
    // ctx.stroke();

    // voronoi.vectors.forEach((vector, i) => {
    //   ctx.beginPath();
    //   ctx.fillStyle = `#${colors[i]}`;
    //   voronoi.renderCell(i, ctx);
    //   ctx.stroke();
    //   ctx.fill()
    // });

    // console.log("p", points);
    const points = distributeEven(numPoints, width, height);
    setPoints(points);
    const controlPoints = generateGrid(width, height);
    setControlPoints(controlPoints);

    const colors = range(points.length).map((i) => sample(schemeSet2));
    // const colors = points.map((cell) =>
    //   Math.floor(Math.random() * 16777215).toString(16)
    // );
    setColors(colors);
  }, [height, numPoints, width]);

  useEffect(() => {
    const delaunay = Delaunay.from(points);
    const voronoi = delaunay.voronoi([0, 0, width, height]);

    let polyCells = Array.from(voronoi.cellPolygons());
    const xline = line()
      .curve(availableCurves[curve])
      .x((d) => d[0])
      .y((d) => d[1]);

    const polys = polyCells.map((cell) => xline(cell));

    // const polys = polyCells.map((cell, i) => voronoi.renderCell(i));
    setCells(polys);
  }, [curve, height, points, width]);

  //   return <svg style={{ width, height, background: "antiquewhite" }}>
  //       {cells.map((cell, i) => {
  //           return <path key={i} d={cell} fill={`#${colors[i]}`} stroke={'#444'} style={{strokeWidth: 5}}></path>
  //       })}
  //   </svg>;

  //   return <canvas ref={ref} width={width} height={height}></canvas>;
  return (
    <div>
      <div style={{ padding: "1rem", display: "flex", alignItems: "center" }}>
        Num points: <b>{numPoints}</b>
        <input
          value={numPoints}
          max={30}
          min={1}
          type="range"
          onChange={(e) => setNumPoints(e.target.value)}
        ></input>
        <select value={curve} onChange={(e) => setCurves(e.target.value)}>
          {Object.keys(availableCurves).map((curveName) => (
            <option key={curveName} value={curveName}>
              {curveName}
            </option>
          ))}
        </select>
      </div>

      <div style={{ width, height, position: "relative" }}>
        <svg
          style={{ position: "absolute", zIndex: 0, background: "#000" }}
          width={width}
          height={height}
          onDrag={(e) => {
            console.log(e);
          }}
        >
          <defs>
            {pictures.map((picture, i) => (
              <pattern
                key={i}
                id={`pic-${i}`}
                patternUnits="userSpaceOnUse"
                width={picture.width}
                height={picture.height}
              >
                <image
                  href={picture.url}
                  x="0"
                  y="0"
                  width={picture.width}
                  height={picture.height}
                />
              </pattern>
            ))}
          </defs>
          {cells.map((cell, i) => {
            return (
              <path
                key={i}
                d={cell}
                fill={`${colors[i]}`}
                stroke={"#000"}
                style={{ strokeWidth: 2  }}
                //   fill={`url(#pic-${i})`}
              ></path>
            );
          })}
          {points.map((p, i) => (
            <circle
              key={i}
              cx={p[0]}
              cy={p[1]}
              r={10}
              onDrag={(e) => {}}
            ></circle>
          ))}
          {/* {controlPoints.map((p, i) => (
        <circle
          key={i}
          cx={p[0]}
          cy={p[1]}
          r={2}
          style={{ fill: "red" }}
        ></circle> */}
          ))}
        </svg>
        <div style={{ position: "absolute", zIndex: 2 }}>
          {points.map((p, i) => (
            <div
              draggable
              key={i}
              style={{
                position: "absolute",
                left: p[0] - 5,
                top: p[1] - 5,
                width: 10,
                height: 10,
                borderRadius: 10,
                background: "red",
              }}
              onDragStart={(e) => {
                let img = document.createElement("span");
                e.dataTransfer.setDragImage(img, 10, 10);
              }}
              onDrag={(e) => {
                if (e.nativeEvent.offsetX > -100) {
                  setPoints(
                    movePoint(
                      points,
                      i,
                      e.nativeEvent.offsetX,
                      e.nativeEvent.offsetY
                    )
                  );
                }
              }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}
